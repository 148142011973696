body {
    font-family: 'Roboto', sans-serif;
}

.nationals {
    margin-bottom: 10px;
}

.alert-knave {
    border-color: #ED0071;
    background-image: linear-gradient(to bottom, #ed165f 0%, #ed165f 100%);
    color: #fff;
}

.knave-logo {
    width: 60%;
    max-width: 400px;
}

.cibt {
    position: relative;
}
.cibt span {
    position: absolute;
    top: 0px;
    right: 0px;
}