.font-awesome-large {
    font-size: 60px;
}

.state-row {
    margin-bottom: 20px;
}

.important-info {
    font-size: 20px;
    font-weight: bold;
}