.no-permit {
    color: red !important;
    font-weight: bold;
    font-size: 16px;
}

.years {
    margin-bottom: 20px;
}

@media (min-width: 991px) {
.navbar .container {
    padding-left: 0;
    padding-right: 0;
    margin-right: 200px;
    margin-left: 50px;
    width: 90%;
}
}

@media (max-width: 991px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
}