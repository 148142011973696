body {
  margin-top: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
    font-family: 'Roboto', sans-serif;
}

.nationals {
    margin-bottom: 10px;
}

.alert-knave {
    border-color: #ED0071;
    background-image: linear-gradient(to bottom, #ed165f 0%, #ed165f 100%);
    color: #fff;
}

.knave-logo {
    width: 60%;
    max-width: 400px;
}

.cibt {
    position: relative;
}
.cibt span {
    position: absolute;
    top: 0px;
    right: 0px;
}
.racer-icon {
    height: 20px;
}

.font-awesome-large {
    font-size: 60px;
}
.panel-title a:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\E114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-title a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\E080";    /* adjust as needed, taken from bootstrap.css */
}

.panel-heading a { 
    width: 100%;
    height: 100%;
    display: block
}
.panel-title a:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\E114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-title a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\E080";    /* adjust as needed, taken from bootstrap.css */
}

.panel-heading a {
    width: 100%;
    height: 100%;
    display: block
}
.no-permit {
    color: red !important;
    font-weight: bold;
    font-size: 16px;
}

.years {
    margin-bottom: 20px;
}

@media (min-width: 991px) {
.navbar .container {
    padding-left: 0;
    padding-right: 0;
    margin-right: 200px;
    margin-left: 50px;
    width: 90%;
}
}

@media (max-width: 991px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
}
.font-awesome-large {
    font-size: 60px;
}

.btn {
    width: 100%;
}
@font-face {
    font-family: 'StateFaceRegular';
    src: url(/static/media/stateface-regular-webfont.1ac73a56.eot);
    src: url(/static/media/stateface-regular-webfont.1ac73a56.eot?#iefix) format('embedded-opentype'),
    url(/static/media/stateface-regular-webfont.801aaed6.woff) format('woff'),
    url(/static/media/stateface-regular-webfont.a30251b9.ttf) format('truetype'),
    url(/static/media/stateface-regular-webfont.02be97c3.svg#StateFaceRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}

.stateface:after {
    font-family: StateFaceRegular;
    margin-left: 5px;
}

.stateface-replace {
    text-indent: -999em;
    display: inline-block;
    position: relative;
    min-width: 1em;
}

.stateface-replace:before {
    position: absolute;
    left: 0;
    top: 0;
    text-indent: 0;
}

.stateface-ak:after {
    content: "A";
}

.stateface-al:after {
    content: "B";
}

.stateface-ar:after {
    content: "C";
}

.stateface-az:after {
    content: "D";
}

.stateface-ca:after {
    content: "E";
}

.stateface-co:after {
    content: "F";
}

.stateface-ct:after {
    content: "G";
}

.stateface-dc:after {
    content: "y";
}

.stateface-de:after {
    content: "H";
}

.stateface-fl:after {
    content: "I";
}

.stateface-ga:after {
    content: "J";
}

.stateface-hi:after {
    content: "K";
}

.stateface-ia:after {
    content: "L";
}

.stateface-id:after {
    content: "M";
}

.stateface-il:after {
    content: "N";
}

.stateface-in:after {
    content: "O";
}

.stateface-ks:after {
    content: "P";
}

.stateface-ky:after {
    content: "Q";
}

.stateface-la:after {
    content: "R";
}

.stateface-ma:after {
    content: "S";
}

.stateface-md:after {
    content: "T";
}

.stateface-me:after {
    content: "U";
}

.stateface-mi:after {
    content: "V";
}

.stateface-mn:after {
    content: "W";
}

.stateface-mo:after {
    content: "X";
}

.stateface-ms:after {
    content: "Y";
}

.stateface-mt:after {
    content: "Z";
}

.stateface-nc:after {
    content: "a";
}

.stateface-nd:after {
    content: "b";
}

.stateface-ne:after {
    content: "c";
}

.stateface-nh:after {
    content: "d";
}

.stateface-nj:after {
    content: "e";
}

.stateface-nm:after {
    content: "f";
}

.stateface-nv:after {
    content: "g";
}

.stateface-ny:after {
    content: "h";
}

.stateface-oh:after {
    content: "i";
}

.stateface-ok:after {
    content: "j";
}

.stateface-or:after {
    content: "k";
}

.stateface-pa:after {
    content: "l";
}

.stateface-ri:after {
    content: "m";
}

.stateface-sc:after {
    content: "n";
}

.stateface-sd:after {
    content: "o";
}

.stateface-tn:after {
    content: "p";
}

.stateface-tx:after {
    content: "q";
}

.stateface-us:after {
    content: "z";
}

.stateface-ut:after {
    content: "r";
}

.stateface-va:after {
    content: "s";
}

.stateface-vt:after {
    content: "t";
}

.stateface-wa:after {
    content: "u";
}

.stateface-wi:after {
    content: "v";
}

.stateface-wv:after {
    content: "w";
}

.stateface-wy:after {
    content: "x";
}

.font-awesome-large {
    font-size: 60px;
}

.state-row {
    margin-bottom: 20px;
}

.important-info {
    font-size: 20px;
    font-weight: bold;
}
.panel-title a:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\E114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-title a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\E080";    /* adjust as needed, taken from bootstrap.css */
}

.panel-heading a {
    width: 100%;
    height: 100%;
    display: block
}
.panel-title a:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\E114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-title a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\E080";    /* adjust as needed, taken from bootstrap.css */
}
